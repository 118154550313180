import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      <Rooms row wrap>
        {uniqBy(rooms, 'title').map((item, index) => (
          <Room key={index} {...item} />
        ))}
      </Rooms>
    </Container>
  )
})

const Container = styled.section`
  padding: 0 7.292vw;

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }
`

const Rooms = styled(FlexBox)`
  margin: 3.125rem -3.75rem 9.375rem 0;

  @media (max-width: 1199px) {
    margin: 1.875rem -1.875rem 5.625rem 0;
  }

  @media (max-width: 767px) {
    margin: 0 0 5.625rem 0;
  }
`
