import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { ChevronsRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  availabilityURL?: string
  beds?: string
  languageCode: string
  pax?: string
  sliderImages?: ImageProps[]
  title: string
}

export const Room = memo(function Room({
  availabilityURL,
  beds,
  languageCode,
  pax,
  sliderImages,
  title,
}: Props) {
  if (!title || !URL) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = React.useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      defaultAnimation: {
        duration: 2000,
      },
      loop: true,
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
      detailsChanged(s) {
        if (sliderImages) {
          sliderImages.length > 1 ? setDetails(s.track.details) : null
        }
      },
    },
    [],
  )

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      {sliderImages ? (
        <Wrapper>
          {beds || pax ? (
            <Infos dial={4} row wrap>
              {pax ? <Info>{pax}</Info> : null}
              {beds ? <Info>{beds}</Info> : null}
            </Infos>
          ) : null}

          <Slider ref={sliderRef}>
            {uniq(sliderImages).map((item, index) => (
              <Slide key={index} className="keen-slider__slide">
                <Inner style={positionStyle(index)}>
                  {loaded[index] ? (
                    <Image media="(min-width: 2800px)" {...item} />
                  ) : (
                    <Spinner />
                  )}
                </Inner>
              </Slide>
            ))}
          </Slider>

          {sliderImages.length > 1 ? (
            <Arrows row>
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              />
            </Arrows>
          ) : null}
        </Wrapper>
      ) : null}

      <Title>
        <Label>{title}</Label>
      </Title>

      {availabilityURL ? (
        <CTA
          label={useVocabularyData('check-availability', languageCode)}
          rel="noopener"
          target="_blank"
          URL={availabilityURL}
          variant="simple"
        />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 3.75rem);
  margin: 6.25rem 3.75rem 0 0;

  @media (max-width: 1199px) {
    width: calc(50% - 1.875rem);
    margin: 3.75rem 1.875rem 0 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 5.625rem 0 0 0;
    &:first-of-type {
      margin-top: 3.75rem;
    }
  }
`

const Wrapper = styled.div`
  margin-bottom: 2.5rem;
  position: relative;

  @media (max-width: 1199px) {
    margin-bottom: 1.875rem;
  }
`

const Infos = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 2rem;
  position: absolute;
  top: 1.875rem;
  left: 1.875rem;
  text-transform: uppercase;
  z-index: 3;

  > div {
    &:nth-of-type(2) {
      display: inline-flex;
      align-items: center;
      &:before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border-radius: 50%;
        margin: 0 0.75rem;
        transform: translateY(-0.0625rem);
      }
    }
  }
`

const Info = styled.div``

const Slider = styled.div`
  display: flex;
  height: 48.9vh;
  max-height: 27.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  outline: none;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.6)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    max-height: 25rem;
  }

  @media (max-width: 1023px) {
    max-height: 18.75rem;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
`

const Title = styled.div``

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.8125rem;
  padding-right: 4.25rem;
  position: relative;
  &:after {
    content: '';
    width: 3rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    top: 1rem;
    right: 0;
  }

  @media (max-width: 1199px) {
    font-size: 1.75rem;
    line-height: 2.25rem;
    &:after {
      top: 0.875rem;
    }
  }

  @media (max-width: 1023px) {
    padding-right: 0;
    &:after {
      display: none;
    }
  }
`

const CTA = styled(Button)`
  margin-top: 1.25rem;

  @media (max-width: 767px) {
    margin-top: 0.75rem;
  }
`
